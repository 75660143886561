@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 98%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 177 61% 48%;
    --primary-foreground: 0 0% 100%;
    --secondary: 240 5% 96%;
    --secondary-foreground: 240 10% 4%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 10% 4%;
    --destructive: 0 72% 51%;
    --destructive-foreground: 0 0% 100%;
    --border: 240 6% 90%;
    --input: 240 5.9% 90%;
    --ring: 142.1 76.2% 36.3%;
    --radius: 0.5rem;
    /* Squeeze colours */
    --main: 0 0% 98%;
    --main-foreground: 240 10% 3.9%;

    --solid: 240 6% 10%;
    --solid-foreground: 0 0% 100%;
    --info: 214 100% 97%;
    --info-foreground: 221 83% 53%;
    --success: 138 76% 97%;
    --success-foreground: 163 94% 24%;
    --error: 0 86% 97%;
    --error-foreground: 0 72% 51%;
    --warning: 45 93% 47%;
    --warning-foreground: 35 92% 33%;
  }

  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 0 0% 95%;
    --card: 24 9.8% 10%;
    --card-foreground: 0 0% 95%;
    --popover: 0 0% 9%;
    --popover-foreground: 0 0% 95%;
    /* --primary: 142.1 70.6% 45.3%; */
    --primary: 177 61% 48%;
    /* --primary-foreground: 144.9 80.4% 10%; */
    --primary-foreground: 224 71% 4%;
    /* --secondary: 240 3.7% 15.9%; */
    --secondary: 240 4% 16%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 15%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 91% 71%;
    --destructive-foreground: 224 71% 4%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 142.4 71.8% 29.2%;
    /* Squeeze colours */
    --main: 20 14.3% 4.1%;
    --main-foreground: 0 0% 95%;

    --solid: 0 0% 98%;
    --solid-foreground: 224 71% 4%;
    --info: 213 94% 68%;
    --info-foreground: 224 71% 4%;
    --success: 156 72% 67%;
    --success-foreground: 224 71% 4%;
    --error: 0 91% 71%;
    --error-foreground: 224 71% 4%;
    --warning: 45 93% 47%;
    --warning-foreground: 224 71% 4%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  html {
    @apply h-full;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      "rlig" 1,
      "calt" 1;
    height: inherit;
  }
  h1 {
    @apply text-2xl font-bold;
  }
  h2 {
    @apply text-xl font-semibold;
  }
  h3 {
    @apply text-base font-semibold;
  }
}

@layer utilities {
  .step {
    counter-increment: step;
  }

  .step:before {
    @apply absolute w-9 h-9 bg-muted rounded-full font-mono font-medium text-center text-base inline-flex items-center justify-center -indent-px border-4 border-background;
    @apply ml-[-50px] mt-[-4px];
    content: counter(step);
  }
}

@media (max-width: 640px) {
  .container {
    @apply px-4;
  }
}

.green-indicator {
  @apply bg-green-300 dark:bg-green-800/90;
}

.neutral-indicator {
  @apply bg-amber-200 dark:bg-amber-700/90;
}

.red-indicator {
  @apply bg-red-300 dark:bg-red-800/90;
}

.negative-heading {
  @apply bg-red-100 dark:bg-red-800 border border-red-300;
}

.positive-heading {
  @apply bg-green-100 dark:bg-green-800 border border-green-300;
}
